import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BidSectionContainer, StyledVehicleBidBuyInformation } from 'modules/BuyerActivity/VehicleAuction.styled'
import { PopoverAttachment } from 'modules/PopoverAttachment'
import { VDPHistory } from 'modules/VDP/VDPHistory'
import { StyledVehicleDetailsBody, StyledVehicleDetailsHeaderRow } from 'modules/VehicleList'
import { useContext } from 'react'
import { Col, OverlayTrigger, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { IVDPSearchParams } from 'types/ISearchParams'
import { IVehicleData } from 'types/vehicleTypes'
import { parseUrlSearchParams } from 'utils/urlParams'
import { UserMaxBid } from './UserMaxBid'
import { VehicleActions } from './VehicleActions'
import { VehicleBidBuyActions } from './VehicleBidBuyActions'
import { VehicleBidHistoryLink } from './VehicleBidHistoryLink'
import { VehicleContext } from './VehicleContext'
import { PaperClipButton, VehicleVin } from './VehicleDetails.styled'
import { VehicleNote } from './VehicleNote'
import { VehiclePriceSection } from './VehiclePriceSection'
import { VehicleProperties } from './VehicleProperties'
import { VehicleTags } from './VehicleTags'
import { VehicleTimer } from './VehicleTimer'
import { VehicleTitleSection } from './VehicleTitleSection'

export interface IProps {
  vehicleData: IVehicleData
}
export const VehicleInformation = ({ vehicleData }: IProps) => {
  const location = useLocation()
  const isVdpHistory = parseUrlSearchParams<IVDPSearchParams>(location.search).history === 'true'
  const hideBuyOptions = parseUrlSearchParams<IVDPSearchParams>(location.search).history === 'true'
  const { retailView } = useContext(VehicleContext)

  return (
    <StyledVehicleDetailsBody>
      <StyledVehicleDetailsHeaderRow>
        <>
          <VehicleTitleSection vehicleData={vehicleData} />
        </>
        <Col sm={'auto'} className="d-print-none vehicle-action">
          <VehicleActions vehicleData={vehicleData} />
        </Col>
      </StyledVehicleDetailsHeaderRow>
      <Row>
        <Col md={8}>
          <VehicleVin>
            {vehicleData.vehicle.VIN}
            {vehicleData.vehicle.HasAttachments && !isVdpHistory && (
              <OverlayTrigger
                placement="bottom"
                trigger="click"
                rootClose
                overlay={(props) => (
                  <PopoverAttachment
                    VIN={vehicleData.vehicle.VIN}
                    vehicleInstanceID={vehicleData.vehicle.InstanceID}
                    {...props}
                  />
                )}
              >
                <PaperClipButton variant="link">
                  <FontAwesomeIcon icon={faPaperclip} title="Click Here to View Attachments" />
                </PaperClipButton>
              </OverlayTrigger>
            )}
          </VehicleVin>
          <VehicleProperties vehicleInfo={vehicleData.vehicle} />
          <VehicleNote />
        </Col>
        {hideBuyOptions && vehicleData.PurchaseHistory ? (
          <Col md={4}>
            <VDPHistory vehiclePurchase={vehicleData.PurchaseHistory} />
          </Col>
        ) : (
          <>
            {!retailView && (
              <Col md>
                <StyledVehicleBidBuyInformation>
                  <VehiclePriceSection />
                  <VehicleTags />
                  <VehicleBidHistoryLink />
                  <div className="v-timer">
                    <VehicleTimer />
                  </div>
                  <BidSectionContainer>
                    <UserMaxBid />
                    <VehicleBidBuyActions />
                  </BidSectionContainer>
                </StyledVehicleBidBuyInformation>
              </Col>
            )}
          </>
        )}
      </Row>
    </StyledVehicleDetailsBody>
  )
}
